<!--
 * @Author: gaojingran
 * @Date: 2021-04-07 16:29:00
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2024-10-14 17:43:32
 * @Description: 创建订单
-->
<style lang="less" scoped>
.new-order {
  padding: 10px 20px 20px;
  /deep/.ant-form-item {
    display: flex;
  }
  .button-wrapper {
    padding-left: 80px;
  }
}

.tip {
  position: absolute;
  top: 18px;
  color: rgb(255, 67, 67);
  font-size: 12px;
}

.service-type {
  &-main {
    max-width: 400px;
  }
  &-fine {
    margin: 0 auto;
    &-tip {
      color: @text_color_2;
    }
  }
}
</style>

<template>
  <NeoPageLayout>
    <a-spin :spinning="loading">
      <div class="new-order">
        <h1 class="mt-4 mb-4 fs-6">{{ $t('newOrder.title') }}</h1>
        <!-- step 1 -->
        <template v-if="step === 1">
          <NeoUploadDragger v-model="sourceFileList" sortType="unshift" :maxLength="20" :maxSize="200" />
          <a-button
            v-if="sourceFileList.length"
            class="mt-4"
            type="primary"
            :disabled="setpOneButtonDisable"
            @click="step = 2"
            >{{ $t('next') }}</a-button
          >
        </template>
        <!-- step 2 -->
        <template v-if="step === 2">
          <div class="order-form">
            <a-form-model ref="orderForm" v-bind="formLayout" :colon="false" :model="form" :rules="rules">
              <a-row :gutter="20">
                <a-col :span="12">
                  <a-form-model-item prop="name" :label="$t('newOrder.order_name')">
                    <a-input
                      allowClear
                      autocomplete="off"
                      v-model="form.name"
                      :placeholder="$t('newOrder.order_name_holder')"
                      @blur="handleInputBlur($event, 'name')"
                    />
                  </a-form-model-item>
                </a-col>

                <a-col :span="12">
                  <a-form-model-item prop="productLineId" :label="$t('newOrder.product_line')">
                    <a-select allowClear v-model="form.productLineId" :placeholder="$t('newOrder.product_line_holder')">
                      <a-select-option v-for="item in productLineList" :key="item.id" :value="item.id">
                        {{ item.name }}
                      </a-select-option>
                    </a-select>
                    <div class="tip" v-if="form.productLineId">
                      {{ $t('newOrder.productLine_tip') }}
                      <!-- 注 -->
                    </div>
                  </a-form-model-item>
                </a-col>

                <a-col :span="12">
                  <a-form-model-item prop="sourceCode" :label="$t('newOrder.source')">
                    <a-select
                      allowClear
                      show-search
                      option-filter-prop="children"
                      v-model="form.sourceCode"
                      :filter-option="langFilterOption"
                      :placeholder="$t('newOrder.source_holder')"
                    >
                      <a-select-option
                        v-for="item in sourceCodeList"
                        :key="item.code"
                        :value="item.code"
                        :disabled="form.targetCodeList.includes(item.code)"
                      >
                        {{ item.name }}
                      </a-select-option>
                    </a-select>
                  </a-form-model-item>
                </a-col>

                <a-col :span="12">
                  <a-form-model-item prop="targetCodeList" :label="$t('newOrder.target')">
                    <a-select
                      allowClear
                      mode="multiple"
                      :maxTagTextLength="20"
                      :token-separators="[',']"
                      v-model="form.targetCodeList"
                      @change="handleTargetCodeChange"
                      :filter-option="langFilterOption"
                      :placeholder="$t('newOrder.target_holder')"
                    >
                      <a-select-option
                        v-for="item in sortedTargetCodeList"
                        :key="item.code"
                        :value="item.code"
                        :disabled="form.sourceCode === item.code"
                      >
                        <template v-if="item.isDuplicate">
                          {{ item.outsideText }}(<span style="color: #fb5549">{{ item.insideText }}</span
                          >)
                        </template>
                        <template v-else>
                          {{ item.name }}
                        </template>
                      </a-select-option>
                    </a-select>
                  </a-form-model-item>
                </a-col>

                <a-col :span="12">
                  <a-form-model-item required :label="$t('newOrder.service_type')" style="margin-bottom: 0">
                    <a-row type="flex">
                      <a-col flex="auto" class="service-type-main">
                        <a-form-model-item prop="serviceType" :wrapperCol="{ span: 22 }" style="max-width: 400px">
                          <a-select
                            allowClear
                            v-model="form.serviceType"
                            :placeholder="$t('newOrder.service_type_holder')"
                          >
                            <a-select-option v-for="item in serviceTypeList" :key="item.id" :value="item.id">
                              <a-tooltip placement="topLeft" :title="item.label">
                                {{ item.label }}
                              </a-tooltip>
                            </a-select-option>
                          </a-select>
                        </a-form-model-item>
                      </a-col>

                      <a-col flex="290px" class="service-type-fine">
                        <a-form-model-item class="fr" prop="fine" :wrapperCol="{ span: 24 }">
                          <a-checkbox v-model="form.composeType">
                            {{ $t('newOrder.fine') }}
                          </a-checkbox>
                          <span class="service-type-fine-tip">{{ $t('stone.newOrder.fine_tip') }}</span>
                          <!--<a-tooltip placement="top" :title="$t('newOrder.fine_tip')">-->
                          <!--  <a-icon type="question-circle" />-->
                          <!--</a-tooltip>-->
                        </a-form-model-item>
                      </a-col>
                    </a-row>
                  </a-form-model-item>
                </a-col>

                <a-col :span="12">
                  <a-form-model-item required prop="supplierId" :label="$t('newOrder.supplier')">
                    <a-select
                      showArrow
                      allowClear
                      v-model="form.supplierId"
                      :placeholder="$t('newOrder.supplier_holder')"
                    >
                      <a-select-option v-for="item in supplierList" :key="item.supEntityId" :value="item.supEntityId">
                        {{ item.supEntityName }}
                        <span v-if="item.isRecommend"><img src="@/assets/image/recommend.png" /> </span>
                      </a-select-option>
                    </a-select>
                    <div style="color: red; line-height: 25px" v-if="supplierTipShow2 && supplierTipShow3">
                      {{
                        $t('validate.supplier_info', {
                          s: getCodeName(form.sourceCode),
                          t: getCodesName(invalidTargetCodeList),
                          documentType: getDocTypeName(form.documentType),
                          serviceType: getTypeName(form.serviceType),
                        })
                      }}
                    </div>
                    <div class="tip" v-else>
                      <div v-if="form.supplierId">
                        {{ $t('newOrder.supplier_tip') }}
                      </div>
                    </div>
                  </a-form-model-item>
                </a-col>

                <!-- 文档类型 默认为文档类 -->
                <a-col :span="12" hidden>
                  <a-form-model-item prop="documentType" :label="$t('newOrder.doc_type')">
                    <a-select allowClear v-model="form.documentType" :placeholder="$t('newOrder.doc_type_holder')">
                      <a-select-option v-for="item in documentTypeList" :key="item.id" :value="item.id">
                        {{ item.label }}
                      </a-select-option>
                    </a-select>
                  </a-form-model-item>
                </a-col>

                <a-col :span="12">
                  <a-form-model-item required prop="dueDate" :label="$t('newOrder.delivery_date')">
                    <a-date-picker
                      style="width: 100%"
                      allowCler
                      valueFormat="YYYY-MM-DD HH:mm:00"
                      v-model="form.dueDate"
                      :showTime="timePickerOption"
                      :format="$t('dateFormat.a')"
                    />
                  </a-form-model-item>
                </a-col>
              </a-row>
              <a-row>
                <a-col :span="24">
                  <a-form-model-item prop="remark" :label="$t('newOrder.order_note')">
                    <a-textarea
                      style="width: 100%"
                      v-model="form.remark"
                      :placeholder="$t('newOrder.order_note_holder')"
                      :auto-size="{ minRows: 3, maxRows: 5 }"
                      @blur="handleInputBlur($event, 'remark')"
                    />
                  </a-form-model-item>
                </a-col>
                <a-col :span="24">
                  <a-form-model-item prop="attachment" style="margin-bottom: 0">
                    <div style="padding-left: 80px">
                      <NeoUploadDragger
                        fileType="tag"
                        v-model="form.attachmentFileList"
                        sortType="unshift"
                        :placeholder="$t('uploadDragger.upload_attachment_file')"
                        :defaultCollapsed="true"
                        :collapsible="false"
                        :maxLength="20"
                        :maxSize="200"
                      />
                    </div>
                  </a-form-model-item>
                </a-col>
              </a-row>
            </a-form-model>
            <!-- button -->
            <div class="button-wrapper" :class="!form.attachmentFileList.length ? 'mt-4' : 'mt-1'">
              <a-space :size="10">
                <a-button class="neo-btn-primary" @click="step = 1">
                  {{ $t('prev') }}
                </a-button>
                <a-button class="neo-btn-primary" @click="handleSaveDraft">
                  {{ $t('newOrder.save_draft') }}
                </a-button>
                <a-button class="neo-btn-primary" @click="setNoticeMail">
                  {{ $t('newOrder.copy_email_to') }}
                  <!--邮件抄送至-->
                </a-button>
                <a-button type="primary" @click="handleGetQuote"> {{ $t('newOrder.submit_order') }} </a-button>
                <!-- <NeoTips :label="$t('newOrder.note_label')" :text="$t('newOrder.note_desc')" /> -->
              </a-space>
            </div>
          </div>
        </template>

        <NoticeMail ref="NoticeMail" :show="noticeMail" :id="qId" @close="noticeMail = false" />
      </div>
    </a-spin>
  </NeoPageLayout>
</template>

<script>
import moment from 'moment'
import { mapState } from 'vuex'
import { Icon } from 'ant-design-vue'
import NeoUploadDragger from '@/components/upload/NeoUploadDragger'
// import NeoTips from '@/components/common/NeoTips'
import { uuid } from '@/utils/utils'
import NoticeMail from './components/NoticeMail.vue'
const { validate, acceptMap, documentValue } = window.$g

export default {
  name: 'NewOrder',
  components: {
    NeoUploadDragger,
    // NeoTips,
    NoticeMail,
  },
  data() {
    return {
      // 供应商列表
      supplierList: [],
      loading: false,
      acceptMap,
      step: 1,
      formLayout: {
        labelCol: { flex: '80px' },
        wrapperCol: { flex: 'auto' },
      },
      timePickerOption: {
        format: 'HH:mm',
      },
      // 产线
      productLineList: [],
      // 翻译文件
      sourceFileList: [],
      // 订单表单
      form: {
        productLineId: undefined,
        sourceCode: undefined,
        targetCodeList: [],
        name: '',
        dueDate: undefined,
        serviceType: undefined,
        documentType: undefined,
        composeType: false, // 排版类型 0-简排 1-精排
        attachmentFileList: [],
        remark: '',
        supplierId: undefined,
        cmReview: false,
      },
      // 表单验证
      rules: {
        productLineId: [validate.required],
        dueDate: [validate.required, validate.earlier_next_36_hours],
        sourceCode: [validate.required],
        targetCodeList: [validate.required],
        name: [{ ...validate.required, trigger: 'blur' }, validate.length_3_50],
        documentType: [validate.required],
        serviceType: [validate.required],
        remark: [validate.size_1000],
        supplierId: [validate.required],
      },
      // -----------------
      serviceTypeList: [],
      documentTypeList: [],
      // ------
      sourceCodeList: [],
      targetCodeList: [],
      validList: [],
      supPairMap: [],
      sourceCodeListInit: [],
      targetCodeListInit: [],
      mappingLangPair: [],
      supplierTipShow: false,
      supplierTipShow3: true,
      BiMap: null,
      noticeMail: false,
      qId: undefined,
      isRepeat: false,
    }
  },
  computed: {
    // 步骤一按钮禁用状态
    setpOneButtonDisable() {
      // 当文件不存在合法 以及 存在正在上传的文件
      const validFile = this.sourceFileList.find((v) => v.status === 'uploading')
      return this.$is.Defined(validFile)
    },

    // 语言列表
    ...mapState('app', {
      langList: (state) => state.langList,
      userInfo: (state) => state.userInfo,
    }),
    entityId() {
      return this.$store.state.app.userInfo.agencyId
    },
    supplierInfo() {
      const f = this.form
      const result = !!(
        f.serviceType &&
        f.supplierId &&
        f.sourceCode &&
        f.targetCodeList.length &&
        this.supplierTipShow
      )
      return result
    },
    invalidTargetCodeList() {
      const f = this.form
      const needShowCheck = !!(
        f.productLineId &&
        f.serviceType &&
        f.documentType &&
        f.supplierId &&
        f.sourceCode &&
        f.targetCodeList.length
      )
      if (!needShowCheck) return []

      const validPair = this.supPairMap[f.supplierId]
      if (!validPair) return [...f.targetCodeList]

      const validTarget = validPair
        .filter((p) => p.serviceId === f.serviceType && p.documentId === f.documentType)
        .filter((p) => p.sourceCode === f.sourceCode)
        .filter((p) => f.targetCodeList.includes(p.targetCode))
        .map((p) => p.targetCode)
      return f.targetCodeList.filter((t) => !validTarget.includes(t))
    },
    sortedTargetCodeList() {
      if (!this.targetCodeList || !this.targetCodeList.length) {
        return this.targetCodeList
      }
      const arr = [...this.targetCodeList]
      return arr.sort((a, b) => {
        if (a.code < b.code) {
          return -1
        }
        if (a.code > b.code) {
          return 1
        }
        return 0
      })
    },

    supplierTipShow2() {
      return this.invalidTargetCodeList.length > 0
    },
  },
  watch: {
    'form.productLineId': {
      deep: true,
      handler() {
        this.getSupList()
      },
    },
    'form.sourceCode': {
      deep: true,
      handler(code) {
        console.log(code)
        if (code) {
          // item[0]=>obj  item[1]=>obj_arr
          // 修改目标语言下拉列表
          console.log(`this.mappingLangPair`, this.mappingLangPair)
          const target = (this.mappingLangPair.find((item) => {
            console.log(`item`, item)
            return item[0].code === code
          }) || [])[1]
          console.log(`target`, target)
          this.targetCodeList = this.getLangBracket(target)
        } else {
          console.log('源语言被清空')
          this.targetCodeList = this.getLangBracket(this.targetCodeListInit)
        }
        this.getSupList()
        this.updateSupMatchTip()
      },
    },
    'form.targetCodeList': {
      deep: true,
      handler(codes) {
        console.log(codes)
        if (codes.length) {
          // 修改目标语言下拉列表
          const target = this.mappingLangPair.filter((item) => {
            // console.log(`item`, item)
            const codes1 = item[1].map((_item) => _item.code)
            const result = this.$g.includes(codes, codes1)
            // console.log('---------↓---------')
            // console.log(`codes1`, codes1)
            // console.log(`codes2`, codes2)
            // console.log(`result`, result)
            // console.log('---------↑---------')

            return result
          })
          let _target = []
          if (target.length) {
            _target = target.map((item) => {
              console.error(`item`, item)
              return item[0]
            })
          }
          console.log(`_target`, _target)
          this.sourceCodeList = _target
        } else {
          this.sourceCodeList = this.sourceCodeListInit
          console.log('目标语言被清空')
        }
        this.getSupList()
        this.updateSupMatchTip()
      },
    },
    'form.supplierId': {
      deep: true,
      handler() {
        this.updateSupMatchTip()
        this.updateSupplierTipShow3()
      },
    },
    'form.serviceType': {
      deep: true,
      handler() {
        this.updateSupplierTipShow3()
      },
    },
    'form.documentType': {
      deep: true,
      handler() {
        this.updateSupplierTipShow3()
      },
    },
    productLineList(arr) {
      if (arr.length === 1) {
        this.form.productLineId = arr[0].id
      }
    },
    supplierList(arr) {
      if (arr.length === 1) {
        this.form.supplierId = arr[0].supEntityId
      }
    },
    sourceFileList(list) {
      if (list.length > 0) {
        const names = list.map((item) => item.name)
        const nameSet = new Set(names)
        if (names.length == nameSet.size) {
          this.isRepeat = false
        } else {
          this.isRepeat = true
        }
      }
    },
    isRepeat(bool) {
      if (bool) {
        this.$message.error(this.$t('trans_record.file_exit'))
        for (let i = 0; i < this.sourceFileList.length; i++) {
          for (let j = i + 1; j < this.sourceFileList.length; j++) {
            if (this.sourceFileList[i].name === this.sourceFileList[j].name) {
              this.sourceFileList.splice(j, 1)
              j--
            }
          }
        }
      }
    },
  },
  mounted() {
    this.fetchAll()
    this.BiMap = this.$g.BiMap.create()
    this.BiMap.sets([
      [0, false],
      [1, true],
    ])
  },
  methods: {
    // 区分语言中括号内外的内容
    handleLangBracketText(str) {
      // 正则表达式，匹配括号内的内容
      let regex = /\((.*?)\)/
      // 使用正则表达式的exec方法来匹配字符串
      let match = regex.exec(str)

      // 括号外的内容
      let outsideText = ''
      // 括号内的内容
      let insideText = ''
      // 如果匹配成功，则match[0]是整个匹配项，match[1]是括号内的内容
      if (match) {
        // 提取括号外的文案
        outsideText = str.replace(match[0], '')
        // 提取括号内的文案
        insideText = match[1]
      }
      return { outsideText, insideText }
    },
    getLangBracket(langs) {
      const newLangs = langs.map((item) => {
        const { outsideText, insideText } = this.handleLangBracketText(item.name)
        return {
          ...item,
          outsideText,
          insideText,
        }
      })
      // 使用filter方法获取重复的元素
      let duplicates = newLangs
        .map((item) => item.outsideText)
        .filter((item, index, array) => array.indexOf(item) !== index)
      const resLangs = newLangs.map((item) => {
        return {
          ...item,
          isDuplicate: duplicates.includes(item.outsideText),
        }
      })
      return resLangs
    },
    // 时间禁用
    disabledDate(current) {
      // 禁用当前时间之前和当前时间48小时之后的日期
      return current && current < moment().add(48, 'hours')
    },
    disabledDateTime() {
      // 禁用当前时间之前和当前时间48小时之后的日期
      return {
        disabledHours: () => {
          let hours = []
          for (let i = 0; i < 24; i++) {
            if (i < moment().hour() || i > moment().hour() + 48) {
              hours.push(i)
            }
          }
          return hours
        },
        disabledMinutes: () => {
          let minutes = []
          for (let i = 0; i < 60; i++) {
            if (i < moment().minute()) {
              minutes.push(i)
            }
          }
          return minutes
        },
        disabledSeconds: () => [],
      }
    },
    async updateSupplierTipShow3() {
      const arr = this.serviceTypeList
        .filter((_) => _.value == 1)
        .flat()
        .map((_) => _.id)

      if (arr.includes(this.form.serviceType)) {
        this.supplierTipShow3 = true
        return
      }
      // else{
      //   this.supplierTipShow3 = false
      //   return
      // }

      try {
        if (!this.form.supplierId) {
          this.supplierTipShow3 = false
          return
        }

        const params = { supEntityId: this.form.supplierId }
        // eslint-disable-next-line no-unused-vars
        const data = await this.$http('getPriceListBySupId', params)
        console.error(`下单监听data`, data)
        const noEmpty = (a, b) => {
          const empty = ['', null, undefined]

          return empty.includes(a) || empty.includes(b)
        }

        const { serviceType, documentType } = this.form

        const data2 = data.filter((item) => {
          return item.documentId === documentType && item.serviceId === serviceType
        })
        console.error('data2 :>> ', data2)

        const isHide = data2.some((item) => noEmpty(item.sourceCode, item.targetCode))
        console.error('isHide :>> ', isHide)
        if (isHide) {
          this.supplierTipShow3 = false
        } else {
          this.supplierTipShow3 = true
        }
      } catch (err) {
        this.$httpNotify(err)
      }
    },
    setNoticeMail() {
      this.noticeMail = true
    },
    updateSupMatchTip() {
      const supplierId = this.form.supplierId
      const currentLangPair = this.form.sourceCode + '-' + this.form.targetCodeList.join(',')
      if (!(this.form.sourceCode && this.form.targetCodeList.length && supplierId)) {
        this.supplierTipShow = false
        return
      }
      const supPairArr = this.$g.flatten(
        Object.keys(this.supPairMap)
          .map((id) => {
            if (id === supplierId) {
              const str_arr = this.supPairMap[id].map(({ sourceCode, targetCode }) => {
                return sourceCode + '-' + targetCode
              })
              return str_arr
            }
          })
          .filter(Boolean)
      )
      if (!supPairArr.length || supPairArr.excludes(currentLangPair)) {
        this.supplierTipShow = true
      } else {
        this.supplierTipShow = false
      }
    },
    getCodeName(code = '') {
      return this.$store.getters['app/getLangNameByCode'](code) || '---'
    },
    getCodesName(codes = []) {
      console.log(`codes`, codes)
      const result = codes.map((code) => {
        return this.$store.getters['app/getLangNameByCode'](code)
      })
      console.error(`result`, result)
      return result || '---'
    },
    getTypeName(id = '') {
      return this.serviceTypeList.find((item) => item.id === id)?.label
    },
    getDocTypeName(id = '') {
      return this.documentTypeList.find((item) => item.id === id)?.label
    },
    handleLangPairData(validList) {
      const arrs = this.$g.sortClass(validList, 'sourceCode')
      const mappingLangPair = arrs.map((arr) => {
        // arr的每个sourceCode都是一样的，只有targetCode不一样
        // 输出的格式：[{code:'zh_CN'...},[{code:jp_JP...},{name:en_US...}] ]
        const sourceCode = this.langList.find((item) => item.code === arr[0].sourceCode)
        const targetCodes = arr
          .map((a) => a.targetCode)
          .filter(Boolean)
          .map((code) => {
            const _item = this.langList.find((item) => item.code === code)
            return _item
          })

        const item = [sourceCode, targetCodes]
        return item
      })
      const sourceCodeListInit = mappingLangPair.map((item) => item[0])
      const targetCodeListInit = this.$g.flatten(mappingLangPair.map((item) => item[1]))
      this.mappingLangPair = mappingLangPair
      this.sourceCodeListInit = sourceCodeListInit
      this.targetCodeListInit = targetCodeListInit

      return { sourceCodeListInit, targetCodeListInit }
    },
    async getOrderPairInfo() {
      try {
        const data = await this.$http('getOrderPairInfo')
        let sourceCodeList = []
        let targetCodeList = []
        const { validList, supPairMap } = data
        this.validList = validList
        this.supPairMap = supPairMap
        if (validList.length) {
          const { sourceCodeListInit, targetCodeListInit } = this.handleLangPairData(validList)
          sourceCodeList = sourceCodeListInit
          targetCodeList = targetCodeListInit
        } else {
          sourceCodeList = this.langList
          targetCodeList = this.langList
        }
        this.sourceCodeList = sourceCodeList
        this.targetCodeList = this.getLangBracket(targetCodeList)
      } catch (err) {
        this.$httpNotify(err)
      }
    },
    async orderTemp() {
      try {
        const data = await this.$http('orderTemp')
        console.log(`表单数据`, data)
        if (!data) {
          return
        }
        const {
          productLineId,
          sourceCode,
          targetCodeList,
          // name,
          dueDate,
          serviceType,
          documentType,
          composeType,
          sourceFileList,
          remark,
          supplierId,
        } = data

        this.sourceFileList = sourceFileList || []

        console.error('this.supplierList :>> ', this.supplierList)

        this.form = {
          productLineId: productLineId || undefined,
          sourceCode: sourceCode || undefined,
          targetCodeList: targetCodeList ? targetCodeList.split(',') : [],
          // name: name || undefined,
          name: undefined,
          dueDate: dueDate || undefined,
          serviceType: serviceType || undefined,
          documentType: documentType || undefined,
          remark: remark || undefined,
          supplierId,
          composeType: Boolean(composeType),
          attachmentFileList: this.form.attachmentFileList,
        }
        this.loading = false
        console.error('this.form :>> ', this.form)
      } catch (err) {
        this.$httpNotify(err)
      }
    },
    async fetchAll() {
      this.getCusProductLineList()
      this.getCusServiceTypeList()
      await this.getCusDocumentTypeList()
      this.form.documentType = this.documentTypeList.find((item) => item.value === documentValue.doc)?.id
      // 获取供应商列表
      this.getSupList()
      if (this.$is.Defined(this.$route.query.id)) {
        this.qId = this.$route.query.id
        await this.getInfo()
        this.getOrderPairInfo()
      } else {
        this.qId = undefined
        await this.orderTemp()
        this.getOrderPairInfo()
      }
    },
    // 客户产品线表-列表
    async getCusProductLineList() {
      try {
        const data = await this.$http('cusProductLineList', { entityId: this.entityId })
        this.productLineList = data.sort(function (a, b) {
          return a.name.localeCompare(b.name, 'zh')
        })
      } catch (err) {
        this.$httpNotify(err)
      }
    },
    // 客户服务类型表-列表
    async getCusServiceTypeList() {
      try {
        const data = await this.$http('cusMaterialList', { entityId: this.entityId, type: 1 })
        // this.serviceTypeList = data
        this.serviceTypeList = data.filter(({ visibleOnPlace }) => Number(visibleOnPlace) === 1)
      } catch (err) {
        this.$httpNotify(err)
      }
    },
    // 客户文档类型表-列表
    async getCusDocumentTypeList() {
      try {
        const data = await this.$http('cusMaterialList', { entityId: this.entityId, type: 2 })
        this.documentTypeList = data
        console.log('文档类型', data)
      } catch (err) {
        this.$httpNotify(err)
      }
    },

    // -----------------↑↑↑---------------------
    langFilterOption(input, option) {
      return option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
    },

    handleInputBlur(ev, key) {
      this.form[key] = ev.target.value
    },

    getFormData() {
      // ar data= datas.filter((item) => item.id == "1")
      console.log('333', this.documentTypeList)
      let obj = this.documentTypeList.filter((item) => item.value === '1160061850967879718')
      console.log('444', obj)
      this.form.documentType = obj[0].id
      console.log(this.from)
      const data = {
        ...this.form,
        cmReview: this.BiMap.get(this.form.cmReview),
        targetCodeList: this.form.targetCodeList.join(','),
        composeType: Number(this.form.composeType),
        sourceFileList: this.sourceFileList
          .filter((v) => v.status === 'done')
          .map((v) => ({
            name: v.name,
            url: v.url,
          })),
      }
      console.log('表单信息', data)
      return data
    },

    //  attachmentFileList: this.form.attachmentFileList
    //     .filter((v) => v.status === 'done')
    //     .map((v) => ({
    //       name: v.name,
    //       url: v.url,
    //     }))

    handleTargetCodeChange() {
      const newArray = []
      const errorArray = []
      this.form.targetCodeList.forEach((code) => {
        const n = this.langList.find((v) => v.name === code)
        const c = this.langList.find((v) => v.code === code)
        if (c) {
          return newArray.push(code)
        }
        if (n) {
          // 假设code为name的话替换为code
          return newArray.push(n.code)
        }
        return errorArray.push(code)
      })
      this.form.targetCodeList = newArray
      if (errorArray.length) {
        this.$message.error(this.$t('newOrder.error_lang_tips', { language: errorArray.join(', ') }))
      }
    },

    // 获取草稿箱详情
    async getInfo() {
      try {
        this.loading = true

        const data = await this.$http({
          key: 'orderProcessInfo',
          params: {
            id: this.$route.query.id,
          },
        })
        console.warn('草稿箱---')
        console.log(data)
        const {
          id,
          productLineId,
          sourceCode,
          targetCodeList,
          name,
          dueDate,
          serviceType,
          documentType,
          composeType,
          attachmentFileList,
          sourceFileList,
          remark,
          supplierId,
          cmReview,
        } = data

        console.log('attachmentFileList :>> ', attachmentFileList)

        this.sourceFileList = sourceFileList.map((v) => ({
          uid: uuid(),
          name: v.name,
          status: 'done',
          percent: 100,
          url: v.url,
        }))

        this.form = {
          id,
          productLineId: productLineId || undefined,
          sourceCode: sourceCode || undefined,
          targetCodeList: targetCodeList ? targetCodeList.split(',') : [],
          name: name || undefined,
          dueDate: dueDate || undefined,
          serviceType: serviceType || undefined,
          documentType: documentType || undefined,
          remark: remark || undefined,
          supplierId,
          composeType: Boolean(composeType),
          attachmentFileList,
          cmReview: this.BiMap.get(cmReview),
        }
        this.loading = false
      } catch (err) {
        this.loading = false
        this.$httpNotify(err)
      }
    },
    // supplierId: this.supplierList.includes(supplierId) ? supplierId : undefined,
    //         attachmentFileList: attachmentFileList
    //           .filter((v) => v.tag === 1)
    //           .map((v) => ({
    //             uid: uuid(),
    //             name: v.name,
    //             status: 'done',
    //             percent: 100,
    //             url: v.url,
    //           })),

    // 保存草稿箱
    async handleSaveDraft() {
      try {
        this.loading = true
        const fd = this.getFormData()
        // eslint-disable-next-line no-unused-vars
        const id = await this.$http('processSave', fd)
        this.loading = false
        this.$success({
          title: this.$t('prompt.save_success'),
          icon: (h) => h(Icon, { props: { type: 'check-circle', theme: 'filled' } }),
          okText: this.$t('confirm'),
          onOk: async () => {
            const result = await this.$refs.NoticeMail.saveNoticeStone(id)
            console.log('result :>> ', result)
            this.canLeave = true
            // 草稿箱
            this.$router.push({
              name: 'order_draftBox',
            })
          },
        })
      } catch (err) {
        this.loading = false
        this.$httpNotify(err)
      }
    },

    // 获取报价
    handleGetQuote() {
      this.$refs.orderForm.validate((valid) => {
        if (valid) {
          this.$confirm({
            title: this.$t('newOrder.get_quote'),
            content: this.$t('newOrder.get_quote_desc'),
            icon: (h) => h(Icon, { props: { type: 'exclamation-circle', theme: 'filled' } }),
            okText: this.$t('confirm'),
            cancelText: this.$t('cancel'),
            onOk: async () => {
              try {
                const fd = this.getFormData()
                const id = await this.$http('processPresent', fd)
                const result = await this.$refs.NoticeMail.saveNoticeStone(id)
                console.warn('processPresent-result :>> ', result)
                this.canLeave = true
                // 跳订单详情
                this.$router.push({
                  path: this.$g.route.order_orderInfo,
                  query: {
                    id,
                    extraName: this.form.name,
                  },
                })
              } catch (err) {
                this.$httpNotify(err)
              }
            },
          })
        }
      })
    },
    // 获取供应商列表
    async getSupList() {
      try {
        const { sourceCode, targetCodeList, productLineId: supProductLineId } = this.form
        this.supplierList = await this.$http('getSupList', { sourceCode, targetCodeList, supProductLineId })
      } catch (err) {
        this.$httpNotify(err)
      }
    },
  },
  beforeRouteLeave(to, from, next) {
    const sourceFileList = this.sourceFileList.filter((v) => v.status === 'done')
    if (sourceFileList.length && !this.canLeave && to.name !== 'login') {
      this.$confirm({
        title: this.$t('newOrder.prompt_save_draft'),
        icon: (h) => h(Icon, { props: { type: 'exclamation-circle', theme: 'filled' } }),
        okText: this.$t('confirm'),
        cancelText: this.$t('cancel'),
        onCancel: () => {
          next()
        },
        onOk: async () => {
          try {
            const fd = this.getFormData()
            const id = await this.$http('processSave', fd)
            const result = await this.$refs.NoticeMail.saveNoticeStone(id)
            console.log('result :>> ', result)
            this.canLeave = true
            next('/order/draftBox')
          } catch (err) {
            this.$httpNotify(err)
          }
        },
      })
    } else {
      next()
    }
  },
}
</script>
